.SharingEdit-newshare-item {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: baseline;
}

.SharingEdit-newshare-label {
    overflow-wrap: normal;
}

.SharingEdit-newshare-input {
    width: 200px;
}
