.SignInPage-container {
    display: flex;
    flex-direction: column;
    width: 300px;
    gap: 5px;
    margin: 5px;
}

.SignInPage-input-row {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.SignInPage-input-label {
    width: 100px;
}

.SignInPage-action-row {
    text-align: right;
}
