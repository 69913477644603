.CreateWorld-detail-row {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin: 5px 0px;
}

.CreateWorld-label {
    margin-right: 5px;
}

.CreateWorld-input {
    text-align: right;
}

.CreateWorld-domain {
    width: max-content;
    font-style: italic;
    white-space: nowrap;
}

.CreateWorld-edition-select {
    display: flex;
    flex-direction: row;
    width: 200px;
}
