.ServerPropertiesEdit-row {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: baseline;
}

.ServerPropertiesEdit-label {
    width: 200px;
    text-align: right;
}

.ServerPropertiesEdit-input {
    width: 250px;
}

.ServerPropertiesEdit-action {
    width: 80px;
}
