.WorldEdit-general-information-section {}

.WorldEdit-general-information-row {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: baseline;
}

.WorldEdit-general-information-label {
    width: 80px;
}
