.ProfilePage-container {
    display: flex;
    flex-direction: column;
    width: 300px;
    gap: 5px;
    margin: 5px;
}

.ProfilePage-input-row {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: baseline;
}

.ProfilePage-input-label {
    width: 100px;
}

.ProfilePage-action-row {
    text-align: right;
}
